






























































import { ethers } from "ethers";
import { mapGetters, mapActions } from "vuex";

import LoginRequired from "@/components/LoginRequired.vue";

import Greeter from "@/contracts/Greeter.json";
import addresses from "@/contracts/addresses.json";

export default {
  name: "SetValue",
  components: {
    LoginRequired,
  },
  data() {
    return {
      greeting: "",
      newValue: null,
      contract: null,
      waiting: false,
    };
  },
  computed: {
    ...mapGetters("accounts", [
      "isInitialized",
      "isUserConnected",
      "getChainId",
      "getWeb3Provider",
    ]),
  },
  async created() {
    await this.connect();

    if (!this.getWeb3Provider) {
      document.location.href = "/";
    } else {
      // get the contract instance
      let provider = new ethers.providers.Web3Provider(this.getWeb3Provider);
      let signer = provider.getSigner();
      const contractAddress = addresses.Greeter[parseInt(this.getChainId)];
      this.contract = new ethers.Contract(contractAddress, Greeter.abi, signer);

      try {
        const greeting = await this.contract.greet();
        this.greeting = greeting;
      } catch (err) {
        this.$toasted.global.app_error();
        console.log(err);
      }
    }
  },
  methods: {
    ...mapActions("accounts", ["connect"]),
    async onSubmit() {
      this.waiting = true;

      try {
        const transaction = await this.contract.setGreeting(this.newValue);
        await transaction.wait().then((receipt) => {
          this.greeting = this.newValue;
          this.$toasted.global.app_success({
            message: "The new greeting has been set to " + this.newValue,
          });
          this.newValue = "";
          this.waiting = false;
          console.log(receipt);
        });
      } catch (err) {
        this.$toasted.global.app_error();
        this.waiting = false;
        console.log(err);
      }
    },
  },
};
